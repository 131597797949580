import React, { useEffect, useState } from 'react'
import AOS from 'aos'
import 'aos/dist/aos.css'
import { isElementInViewport } from '@raylink-overseas/common/utils'
import { Layout, TopBtn } from '@raylink-overseas/common/components'
import Banner from './components/banner'
import StartNow from './components/startNow'
import VideoCarousel from './components/videoCarousel'
import SecurityCertificate from './components/securityCertificate'
import UserSceneWrapper from './components/userSceneWrapper'
import HighlightedFeatures from './components/highlightedFeatures'
// import UserReviews from './components/userReviews'
import Blogs from './components/Blogs'
import { WarpId } from './contants'
import BusinessIntro from './components/businessIntro'
import RecommendedBy from './components/recommend'

if (typeof document !== 'undefined') {
  AOS.init()
}

export interface PlayStatusType {
  [WarpId.simpleWarp]: boolean
  [WarpId.sceneCarouselWrap]: boolean
  [WarpId.userReviews]: boolean
}

const Index = () => {
  const [playStatus, setPlayStatus] = useState<PlayStatusType>({
    [WarpId.simpleWarp]: false,
    [WarpId.sceneCarouselWrap]: false,
    [WarpId.userReviews]: false,
  })

  useEffect(() => {
    // 有播放效果的组件，当组件出现在浏览器可视化区域大于自身1/2时开始播放效果
    const simpleDom = document.getElementById(WarpId.simpleWarp)
    const sceneDom = document.getElementById(WarpId.sceneCarouselWrap)
    const reviewDom = document.getElementById(WarpId.userReviews)

    const handler = () => {
      setPlayStatus({
        [WarpId.simpleWarp]: !!(simpleDom && isElementInViewport(simpleDom)),
        [WarpId.sceneCarouselWrap]: !!(
          sceneDom && isElementInViewport(sceneDom)
        ),
        [WarpId.userReviews]: !!(reviewDom && isElementInViewport(reviewDom)),
      })
    }
    window.addEventListener('scroll', handler)
    return () => {
      window.removeEventListener('scroll', handler)
    }
  }, [])

  return (
    <Layout pageType="home">
      <Banner />
      <VideoCarousel />
      <SecurityCertificate />
      <HighlightedFeatures />
      <BusinessIntro />
      <UserSceneWrapper playStatus={playStatus} />
      <RecommendedBy />
      {/* <AdvancedFeatures /> */}
      {/* <UserReviews isPlay={playStatus[WarpId.userReviews]} /> */}
      <Blogs />
      <StartNow />
      <TopBtn />
    </Layout>
  )
}

export default Index
