import styled from 'styled-components'
import { BigWrapper } from '@raylink-overseas/common/components'
import { Media, color } from '@raylink-overseas/common/theme'

export const SceneCarouselWrap = styled(BigWrapper)`
  ${Media.homeLaptop`
    width: 1184px;
  `}
  ${Media.laptop`
    width: 1088px;
  `}
  ${Media.ipad`
    width: 936px;
  `}
  ${Media.phone`
    width: 100%;
    padding: 0 0.32rem;
  `}
`

export const Title = styled.h2`
  margin-bottom: 56px;
  color: #1f2329;
  text-align: center;
  font-size: 34px;
  font-weight: 700;
  ${Media.homeLaptop`
    font-size: 32px;
    margin-bottom: 48px;
  `}
  ${Media.laptop`
    font-size: 30px;
    margin-bottom: 40px;
  `}
  ${Media.ipad`
    font-size: 28px;
    margin-bottom: 32px;
  `}

  ${Media.phone`
    margin-bottom: 0.48rem;
    font-size: 0.44rem;
  `}
`

export const SceneWrap = styled.div`
  width: 100%;
  overflow: hidden;
`

export const SceneList = styled.div<{ index: number; start: number }>`
  display: flex;
  transform: translateX(${({ index }) => -index * 1400}px);
  transition: ${({ index, start }) =>
    index === 0 && !start ? 'none' : 'transform 300ms'};
  ${Media.homeLaptop<{ index: number; start: number }>`
    transform: translateX(${({ index }) => -index * 1184}px);
  `}
  ${Media.laptop<{ index: number; start: number }>`
    transform: translateX(${({ index }) => -index * 1088}px);
  `}
  ${Media.ipad<{ index: number; start: number }>`
    transform: translateX(${({ index }) => -index * 942}px);
  `}

  ${Media.phone<{ index: number; start: number }>`
    gap: 0.32rem;
    transform: translateX(${({ index }) => -index * 7.18}rem);
  `};
`

export const SceneItem = styled.div`
  display: flex;
  align-items: center;
  .left {
    padding: 121px 56px 121px 64px;
    width: 420px;
    border-radius: 24px 0 0 24px;
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.9) 0%,
      rgba(255, 255, 255, 0.65) 100%
    );
    .title {
      color: #1f2329;
      font-size: 28px;
      font-weight: 700;
    }
    .text {
      margin: 16px 0 40px;
      color: ${color.textDark};
      font-size: 16px;
      font-weight: 400;
    }
  }
  .right {
    width: 420px;
  }
  ${Media.homeLaptop`
    .left {
      padding: 80px 40px 80px 48px;
      width: 358px;
      .title {
        font-size: 26px;
      }
      .download-btn {
        width: 174px;
        height: 52px;
      }
    }
    .middle {
      width: 468px;
      height: 488px;
      overflow: hidden;
      border-radius: 24px;
      img {
        width: 468px;
        height: 488px;
      }
    }
    .right {
      width: 358px;
      height: 391px;
    }
  `}
  ${Media.laptop`
    .left {
      padding: 58px 32px 58px 48px;
      width: 329px;
      .download-btn {
        width: 160px;
        height: 47px;
      }
    }
    .middle {
      width: 430px;
      height: 448px;
      overflow: hidden;
      border-radius: 24px;
      img {
        width: 430px;
        height: 448px;
      }
    }
    .right {
      width: 329px;
    }
  `}
  ${Media.ipad`
    border-radius: 16px;
    .left {
      width: 285px;
      height: 314px;
      padding: 0 26px 0 40px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      .title {
        font-size: 24px;
      }
      .text {
        font-size: 14px;
        margin: 12px 0 24px;
      }
      .download-btn {
        width: 136px;
        height: 39px;
        font-size: 14px;
      }
    }
    .middle {
      width: 373px;
      height: 369px;
      border-radius: 16px;
      img {
        width: 373px;
        height: 369px;
      }
    }
    .right-div {
      width: 285px;
      height: 315px;
      .right {
        width: 285px;
        height: 315px;
      }
    }
  `}

  ${Media.phone`
    padding: 26px;
    width: 100%;
    height: max-content;
    flex-shrink: 0;
    border-radius: 0.28rem;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.90) 0%, rgba(255, 255, 255, 0.00) 100%, rgba(255, 255, 255, 0.65) 100%);
    flex-direction: column;
    .left {
      margin-bottom: 0.48rem;
      padding: 0;
      width: 100%;
      height: auto;
      border-radius: 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      .title {
        font-size: 0.4rem;
      }
      .text {
        margin: 0.16rem 0 0.2rem;
        font-size: 0.28rem;
        text-align: center;
      }
    }
    .middle {
      display: none;
    }
    .right-div {
      width: 100%;
      height: auto;
      .right {
        width: 5.82rem;
        height: auto;
        border-radius: 0.2rem;
      }
    }
  `}
`

export const BtnWrap = styled.div`
  margin-top: 32px;
  display: flex;
  justify-content: center;
  gap: 16px;
  .arrow-btn {
    width: 48px;
    height: 48px;
    border-radius: 4px;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    &.disabled {
      svg {
        path {
          fill: #a6b0be;
        }
      }
    }
  }
  &.mobil-btn-wrap {
    display: none;
  }
  ${Media.ipad`
    margin-top: 24px;
  `}

  ${Media.phone`
    margin-top: 0;
    margin-bottom: 0.48rem;
    gap: 0.2rem;
    .arrow-btn {
      width: 0.8rem;
      height: 0.8rem;
      border-radius: 0.08rem;
    }
    &.web-btn-wrap {
      display: none;
    }
    &.mobil-btn-wrap {
      display: flex;
    }
  `}
`
