import React, { useEffect, useState } from 'react'
import FreeDownload from '@raylink-overseas/common/components/freeDownload'
import remoteWorkImg from '@images/home/scene/remote-work-img.png'
import remoteWorkScene from '@images/home/scene/remote-work-scene.png'
import ITSupportImg from '@images/home/scene/IT-support-img.png'
import ITSupportScene from '@images/home/scene/IT-support-scene.png'
import remoteGameImg from '@images/home/scene/remote-game-img.png'
import remoteGameScene from '@images/home/scene/remote-game-scene.png'
import remoteDesignImg from '@images/home/scene/remote-design-img.png'
import remoteDesignScene from '@images/home/scene/remote-design-scene.png'
import remoteMaintenance from '@images/home/scene/remote-maintenance.png'
import RemoteMaintenanceScene from '@images/home/scene/remote-maintenance-scene.png'
import ArrowPre from '@svg/arrow-pre.svg'
import ArrowNext from '@svg/arrow-next.svg'
import {
  SceneCarouselWrap,
  Title,
  SceneWrap,
  SceneList,
  SceneItem,
  BtnWrap,
} from './atoms'
import { WarpId } from '../../contants'

const sceneList = [
  {
    title: 'Remote Work',
    text: 'Provide stable connections and clear visuals to meet the needs of remote work for both individuals and enterprise employees.',
    img: remoteWorkImg,
    scene: remoteWorkScene,
  },
  {
    title: 'Design & Production',
    text: 'Unlock the creative potential with ultra-low latency and impeccable color accuracy, revolutionizing the capabilities of designers.',
    img: remoteDesignImg,
    scene: remoteDesignScene,
  },
  {
    title: 'Remote Game',
    text: 'Enjoy an immersive gaming experience with frame rates up to 144 FPS and exclusive features such as the 3D gaming mouse and gamepad.',
    img: remoteGameImg,
    scene: remoteGameScene,
  },
  {
    title: 'IT Support',
    text: 'Enable quick remote connections to provide assistance. Manage multiple devices simultaneously and communicate in real-time.',
    img: ITSupportImg,
    scene: ITSupportScene,
  },
  {
    title: 'Remote Maintenance',
    text: 'Boost efficiency through remote troubleshooting, minimizing downtime and losses. Traceable session logs ensure the service quality.',
    img: remoteMaintenance,
    scene: RemoteMaintenanceScene,
  },
]

const mapList = sceneList.concat([sceneList[0]])

export default function SceneCarousel({ isPlay }: { isPlay: boolean }) {
  const [current, setCurrent] = useState<number>(0)
  const [start, setStart] = useState<boolean>(true)

  useEffect(() => {
    if (!isPlay) return undefined
    const timer = setInterval(
      () => {
        setStart(false)
        setCurrent(current + 1)

        // 轮播过渡结束后把当前设置为第一个
        if (current === mapList.length - 2) {
          const innerTimer = setTimeout(() => {
            setCurrent(0)
            clearTimeout(innerTimer)
          }, 300) // 300是过渡时间
        }
      },
      // 第一个由于300延迟，所以间隔时间减去300
      !start && current === 0 ? 4700 : 5000
    )
    return () => {
      clearInterval(timer)
    }
  }, [current, isPlay])

  const renderArrowBtns = (props: any) => (
    <BtnWrap {...props}>
      <div
        className={`${
          current === 0 || current === mapList.length - 1 ? 'disabled' : ''
        } arrow-btn`}
        onClick={() => {
          if (current === 0 || current === mapList.length - 1) return
          setCurrent(current - 1)
          setStart(true)
        }}
      >
        <ArrowPre />
      </div>
      <div
        className={`${
          current === sceneList.length - 1 ? 'disabled' : ''
        } arrow-btn`}
        onClick={() => {
          if (
            current === sceneList.length - 1 ||
            current === mapList.length - 1
          )
            return
          setCurrent(current + 1)
          setStart(true)
        }}
      >
        <ArrowNext />
      </div>
    </BtnWrap>
  )

  return (
    <SceneCarouselWrap id={WarpId.sceneCarouselWrap}>
      <Title data-aos="fade-up" data-aos-duration="1000" data-aos-offset="60">
        Trusted Remote Desktop App for All Needs
      </Title>
      {renderArrowBtns({
        className: 'mobil-btn-wrap',
        'data-aos': 'fade-up',
        'data-aos-duration': '1000',
        'data-aos-offset': '60',
      })}
      <SceneWrap
        data-aos="fade-up"
        data-aos-duration="1000"
        data-aos-offset="60"
      >
        <SceneList index={current} start={start ? 1 : 0}>
          {mapList.map((item, index) => (
            <SceneItem key={index}>
              <div className="left">
                <h3 className="title">{item.title}</h3>
                <div className="text">{item.text}</div>
                <FreeDownload className="download-btn" />
              </div>
              <div className="middle">
                <img src={item.img} alt="" loading="lazy" />
              </div>
              <div className="right-div">
                <img className="right" src={item.scene} alt="" loading="lazy" />
              </div>
            </SceneItem>
          ))}
        </SceneList>
      </SceneWrap>
      {renderArrowBtns({
        className: 'web-btn-wrap',
      })}
    </SceneCarouselWrap>
  )
}
