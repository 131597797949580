import { BigWrapper } from '@raylink-overseas/common/components'
import styled from 'styled-components'
import Bg1920 from '../../../../images/home/recommend/bg-1920.png'
import Bg1440 from '../../../../images/home/recommend/bg-1440.png'
import Bg1280 from '../../../../images/home/recommend/bg-1280.png'
import Bg1024 from '../../../../images/home/recommend/bg-1024.png'
import Bg375 from '../../../../images/home/recommend/bg-375.png'
import { Media } from '@raylink-overseas/common/theme'

export const RecommendedWrap = styled.div`
  margin-top: 112px;
  margin-bottom: 112px;
  ${Media.homeLaptop`
    margin-top: 88px;
    margin-bottom: 88px;
  `}
  ${Media.laptop`
    margin-top: 64px;
    margin-bottom: 64px;
  `}
  ${Media.ipad`
    margin-top: 56px;
    margin-bottom: 56px;
  `}
  ${Media.phone`
    margin-top: 0.8rem;
    margin-bottom: 0.8rem;
  `}
`
export const RecommendedContent = styled(BigWrapper)`
  ${Media.homeLaptop`
    width: 1200px;
  `}
  ${Media.laptop`
    width: 1136px;
  `}
  ${Media.ipad`
    width: 911px;
  `}
  ${Media.phone`
    width: 100%;
  `}
`
export const Title = styled.h2`
  color: #1f2329;
  font-size: 40px;
  font-weight: 700;
  text-align: center;
  margin-bottom: 56px;
  ${Media.laptop`
    font-size: 32px;
    margin-bottom: 40px;
  `}
  ${Media.ipad`
    font-size: 30px;
    margin-bottom: 24px;
  `}
  ${Media.phone`
    margin-bottom: 0.42rem;
  `}
`
export const RecommendedList = styled.div<{ activityIndex: number }>`
  overflow: hidden;
  .list-inner {
    display: flex;
    gap: 33px;
    transition: transform 0.3s;
    // 移动宽度为 item宽度 * item数量 + item间距 * item数量，下方同理
    transform: translateX(${({ activityIndex }) => -activityIndex * 1433.5}px);
    padding: 8px 0;
  }

  ${Media.homeLaptop<{ activityIndex: number }>`
    .list-inner {
      gap: 32px;
      transform: translateX(${({ activityIndex }) =>
        -activityIndex * 1233.5}px);
    }
  `}

  ${Media.laptop<{ activityIndex: number }>`
    .list-inner {
      transform: translateX(${({ activityIndex }) => -activityIndex * 1168}px);
    }
  `}

  ${Media.ipad<{ activityIndex: number }>`
    .list-inner {
      gap: 16px;
      transform: translateX(${({ activityIndex }) => -activityIndex * 926}px);
    }
  `}
  ${Media.phone<{ activityIndex: number }>`
    display: flex;
    justify-content: center;
    .list-inner {
      width: 6.8rem;
      gap: 0.24rem;
      transform: translateX(0);
      overflow-x: auto;
      scrollbar-width: none;
      &::-webkit-scrollbar {
        display: none;
      }
    }
  `}
`
export const RecommendedItem = styled.div`
  width: 445px;
  height: 404px;
  padding: 48px;
  background-image: url(${Bg1920});
  background-size: 100% 100%;
  background-position: center;
  flex: 0 0 445px;
  transition: transform 0.3s;
  .icon {
    height: 51px;
    cursor: pointer;
  }
  .text {
    margin-top: 24px;
    color: #595959;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
  }
  &:hover {
    transform: translateY(-8px);
  }

  ${Media.homeLaptop`
    width: 379px;
    height: 397px;
    background-image: url(${Bg1440});
    flex: 0 0 379px;
  `}

  ${Media.laptop`
    width: 357px;
    height: 394px;
    background-image: url(${Bg1280});
    flex: 0 0 357px;
    .icon {
      height: 43px;
    }
    .text {
      margin-top: 16px;
    }
  `}
  ${Media.ipad`
    padding: 32px;
    width: 293px;
    height: 368px;
    background-image: url(${Bg1024});
    flex: 0 0 293px;
    .icon {
      height: 46px;
    }
    .text {
      font-size: 16px;
    }
   `}
   ${Media.phone`
      padding: 0.8rem 0.48rem 0;
      width: 5.86rem;
      height: 5.96rem;
      background-image: url(${Bg375});
      background-size: 100% 100%;
      flex: 0 0 5.86rem;
      .text {
        margin-top: 0.32rem;
      }
    `}
`

export const SwitchWrap = styled.div`
  display: flex;
  justify-content: center;
  gap: 18px;
  margin-top: 56px;
  ${Media.laptop`
    margin-top: 40px;
  `}
  ${Media.ipad`
    margin-top: 24px;
  `}
  ${Media.phone`
    display: none;
  `}
`

export const SwitchItem = styled.div<{ isActive: boolean }>`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  ${({ isActive }) =>
    isActive
      ? 'width: 56px;border-radius: 5px;background: #7c7bff;'
      : 'background: #ecefff;'}
  cursor: pointer;
  transition: width 0.3s;
`
