import React, { useState, useEffect, useRef } from 'react'
import {
  RecommendedWrap,
  RecommendedContent,
  Title,
  RecommendedList,
  RecommendedItem,
  SwitchWrap,
  SwitchItem,
} from './atoms'
import FossBytesImg from '../../../../images/home/recommend/fossbytes.png'
import HeadLinesImg from '../../../../images/home/recommend/androidheadlines.png'
import IntoPcImg from '../../../../images/home/recommend/getintopc.png'
import GeekFlareImg from '../../../../images/home/recommend/geekflare.png'
import PhAndroidImg from '../../../../images/home/recommend/phandroid.png'
import GetDroidTipsImg from '../../../../images/home/recommend/getdroidtips.png'
import ILoungeImg from '../../../../images/home/recommend/ilounge.png'
import PtechiesImg from '../../../../images/home/recommend/3ptechies.png'
import TechLatestImg from '../../../../images/home/recommend/tech-latest.png'
import { useMedia } from '@raylink-overseas/common/hooks'

const RecommendedBy = () => {
  const isMedia = useMedia()
  const SwitchCount = 3
  const [activityIndex, setActivityIndex] = useState(0)
  const recommendedList = [
    {
      icon: FossBytesImg,
      text: 'Avica is designed to be user-friendly so anyone can activate it without guidance and facing any learning curve. It has a minimal and instinctual interface that can guide you to establish a remote session and experience incomparable performance. ',
      link: 'https://fossbytes.com/remote-work-avica-remote-desktop-solution',
    },
    {
      icon: HeadLinesImg,
      text: 'The ability to control devices remotely paves the way for the future of computing mobility. With efficient remote connect device apps like Avica now bridging the gap between PCs and phones, staying productive on the go is easier. ',
      link: 'https://www.androidheadlines.com/2024/01/how-to-remote-control-pc-from-android-phone-and-vice-versa.html',
    },
    {
      icon: IntoPcImg,
      text: 'When it comes to remote work, the Avica Remote Desktop software is a top choice. It offers high-quality, uninterrupted remote connections packed with tons of features. ',
      link: 'https://getintopc.com/softwares/network/avica-remote-desktop-free-download/',
    },
    {
      icon: GeekFlareImg,
      text: 'Avica is a secure and user-friendly RDP client that offers a wide range of features for both individuals and businesses. This RDP client offers multiple simultaneous connections, including in 4K resolution with 4:4:4 color and multi-monitor support – perfect for designers, engineers, or gamers.',
      link: 'https://geekflare.com/remote-desktop-client-software/',
    },
    {
      icon: PhAndroidImg,
      text: 'Avica remote desktop app is an all-in-one solution for everyone to search for. Thus, try this software on your Android to access a computer remotely through the instructions mentioned above and uplift your remote work productivity.  ',
      link: 'https://phandroid.com/2024/06/03/5-expert-picks-of-remote-desktop-apps-a-list-for-android-users/',
    },
    {
      icon: GetDroidTipsImg,
      text: 'If you want to seek high-quality results while connecting remotely on your desktop, Avica Remote Desktop is the option to go with. Not only does it simplify the process, but it also ensures that the established connection is up to the mark.',
      link: 'https://www.getdroidtips.com/avica-remote-desktop-user-manual-everything-you-need-to-know/',
    },
    {
      icon: ILoungeImg,
      text: 'Enabling remote desktop functionality on both Mac and iPhone is a simple task, thanks to user-friendly solutions like Avica. With its compatibility, ease of use, and strong security measures, Avica provides a reliable platform for users seeking effective remote access to their devices. ',
      link: 'https://www.ilounge.com/articles/enabling-remote-desktop-on-mac-and-iphone-step-by-step-guide',
    },
    {
      icon: PtechiesImg,
      text: 'You may have noticed the several mentions of Avica, a handy, user-friendly remote desktop app for PC and Android. This tool stands out from the rest because of its smooth operation and excellent resolution.',
      link: 'https://www.3ptechies.com/control-android-phone-remotely.html',
    },
    {
      icon: TechLatestImg,
      text: `it's quite clear that the Avica remote desktop software is a top pick among leading remote desktop software. The reason is its end-to-end encryption to ensure secure sessions and lag-free performance. `,
      link: 'https://tech-latest.com/top-5-best-remote-desktop-software/',
    },
  ]
  const timer = useRef<null | NodeJS.Timeout>(null)
  const startTimer = () => {
    timer.current = setInterval(() => {
      setActivityIndex(pre => (pre === SwitchCount - 1 ? 0 : pre + 1))
    }, 5000)
  }
  const stopTimer = () => {
    clearInterval(timer.current!)
    timer.current = null
  }
  useEffect(() => {
    if (!isMedia) {
      startTimer()
    }
    return () => {
      stopTimer()
    }
  }, [])
  return (
    <RecommendedWrap>
      <RecommendedContent
        data-aos="fade-up"
        data-aos-duration="1000"
        data-aos-offset="60"
      >
        <Title>Recommended by</Title>
        <RecommendedList activityIndex={activityIndex}>
          <div className="list-inner">
            {recommendedList.map((item, index) => (
              <RecommendedItem key={index}>
                <a href={item.link} target="_blank" rel="noreferrer">
                  <img className="icon" src={item.icon} loading="lazy" />
                </a>
                <div className="text">{item.text}</div>
              </RecommendedItem>
            ))}
          </div>
        </RecommendedList>
        <SwitchWrap>
          {Array.from({ length: SwitchCount }).map((_, index) => (
            <SwitchItem
              key={index}
              isActive={activityIndex === index}
              onClick={() => {
                setActivityIndex(index)
                stopTimer()
                startTimer()
              }}
            />
          ))}
        </SwitchWrap>
      </RecommendedContent>
    </RecommendedWrap>
  )
}

export default RecommendedBy
