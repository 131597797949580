import styled from 'styled-components'
import { Media, color } from '@raylink-overseas/common/theme'
import { Warpper } from '@raylink-overseas/common/components'
import bannerBg from '../../../../images/home/banner/bannerBg.png'

export const BannerWrap = styled.div`
  position: relative;
  width: 100%;
  min-width: 1400px;
  height: 720px;
  margin: auto;
  border-radius: 0px 0px 89px 80px;
  overflow: hidden;
  background: url(${bannerBg}) center top no-repeat,
    linear-gradient(90deg, #d7eeff 0%, #fdfeff 50.05%, #c7deff 99.69%);

  ${Media.homeLaptop`
    min-width: 1184px;
    height: 680px;
  `}
  ${Media.laptop`
    min-width: 1088px;
    height: 576px;
  `}
  ${Media.ipad`
    height: 480px; 
    border-radius: 0px 0px 48px 48px;
    min-width: 896px;
  `}
  ${Media.phone`
    min-width: 100%;
    height: 8.52rem;
    border-radius: 0px 0px 0.48rem 0.48rem;
    background: linear-gradient(90deg, #D7EEFF 0%, #FDFEFF 50.05%, #C7DEFF 99.69%);
  `}
`

export const BannerContent = styled(Warpper)`
  position: relative;
  display: flex;
  width: 1400px;
  height: 100%;
  padding-top: 196px;
  ${Media.homeLaptop`
    width: 1184px;
    padding-top: 190px;
  `}
  ${Media.laptop`
    width: 1088px;
    padding-top: 150px;
  `}
  ${Media.ipad`
    width: 896px;
    padding-top: 126px;
  `}
  ${Media.phone`
    width: 100%;
    padding-top: 1.66rem;
  `}
`

export const Left = styled.div`
  display: flex;
  flex-direction: column;
  width: 616px;
  z-index: 2;
  .btns {
    margin-top: 40px;
    .second {
      width: 242px;
    }
  }
  ${Media.homeLaptop`
    width: 538px;
  `}
  ${Media.laptop`
    width: 512px;
    .btns {
      margin-top: 32px;
      .second {
        width: 232px;
      }
    }
  `}
  ${Media.ipad`
    width: 435px;
    .btns {
      margin-top: 24px;
      .second {
        width: 222px;
      }
    }
  `}
  ${Media.phone`
    align-items: center;
    width: 100%;
    text-align: center;
    .btns {
      flex-direction: column;
      margin-top: 0.48rem;
      gap: 0.32rem;
      .first, .second {
        width: 3.92rem;
      }
    }
  `}
`
export const Right = styled.div`
  position: absolute;
  bottom: -3px;
  left: 580px;
  img {
    height: 720px;
  }
  ${Media.homeLaptop`
    top: 48px;
    left: 538px;
    img {
      height: 640px;
    }
  `}
  ${Media.laptop`
    top: 20px;
    left: 512px;
    img {
      height: 576px;
    }
  `}
  ${Media.ipad`
    top: 29px;
    left: 435px;
    img {
      height: 450px;
    }
  `}
  ${Media.phone`
    display: none;
  `}
`

export const Title = styled.div`
  width: 570px;
  display: flex;
  flex-direction: column;
  .name {
    color: ${color.textBlack};
    font-size: 20px;
    font-weight: 500;
  }
  .text {
    color: ${color.textDark};
    font-size: 18px;
    font-weight: 400;
  }
  ${Media.homeLaptop`
    width: 538px;
  `}
  ${Media.laptop`
    width: 522px;
    .name {
      font-size: 18px;
    }
  `}
  ${Media.ipad`
    width: 445px;
    .name {
      font-size: 16px;
    }
    .text {
      font-size: 16px;
    }
  `}
  ${Media.phone`
    width: 6.86rem;
    .name {
      font-size: 0.32rem;
    }
    .text {
      width: 100%;
      font-size: 0.28rem;
    }
  `}
`

export const H1 = styled.h1`
  margin: 16px 0;
  color: ${color.textBlack};
  font-size: 44px;
  font-weight: 800;
  line-height: 114.5%;
  .primary {
    color: ${color.primary};
  }
  ${Media.homeLaptop`
    font-size: 42px;
    margin: 12px 0;
  `}
  ${Media.laptop`
    font-size: 40px;
  `}
  ${Media.ipad`
    font-size: 34px;
    margin: 8px 0;
  `}
  ${Media.phone`
    width: 100%;
    margin: 0.24rem 0;
    font-size: 0.52rem;
  `}
`
