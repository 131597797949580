import React, { useState, useEffect } from 'react'
import certificateISO27001 from '../../../../images/home/certificate/ISO27001.png'
import certificateHIPAA from '../../../../images/home/certificate/HIPAA.png'
import certificateTPN from '../../../../images/home/certificate/TPN.png'
import certificateIabm from '../../../../images/home/certificate/iabm-member.png'
import certificateWHQA from '../../../../images/home/certificate/WHQA.png'
import certificateDPP from '../../../../images/home/certificate/DPP.png'
import certificateDPPproduction from '../../../../images/home/certificate/DPP-production.png'
import certificateDPPbroadcast from '../../../../images/home/certificate/DPP-broadcast.png'
import certificateRedHat from '../../../../images/home/certificate/RedHat.png'
import { Certificate, CertificateWrap, Title, CertificateList } from './atoms'

const dataList = [
  {
    img: certificateISO27001,
    text: 'ISO27001 Certification',
  },
  {
    img: certificateHIPAA,
    text: 'HIPAA Verified',
  },
  {
    img: certificateTPN,
    text: 'TPN Verified',
  },
  {
    img: certificateIabm,
    text: 'Member of iabm',
  },
  {
    img: certificateWHQA,
    text: 'WHQA Certified',
  },
  {
    img: certificateDPP,
    text: 'Member of DPP',
  },
  {
    img: certificateDPPproduction,
    text: 'DPP Production Certified',
  },
  {
    img: certificateDPPbroadcast,
    text: 'DPP Broadcast Certified',
  },
  {
    img: certificateRedHat,
    text: 'Red Hat Certified',
  },
]

export default function SecurityCertificate() {
  const [certificateList] = useState([...dataList, ...dataList])
  const [scrollWidth, setScrollWidth] = useState(0)

  useEffect(() => {
    const carousel = document.querySelector('.carousel-inner')
    if (carousel) {
      setScrollWidth(carousel.clientWidth / 2)
    }
  })
  return (
    <Certificate>
      <CertificateWrap>
        <Title>
          <h2 data-aos="fade-up" data-aos-duration="1000" data-aos-offset="60">
            Industry-Acknowledged Security
          </h2>
          <div
            className="text"
            data-aos="fade-up"
            data-aos-duration="1000"
            data-aos-offset="60"
            data-aos-delay="200"
          >
            To safeguard user data and privacy, Avica offers comprehensive
            end-to-end AES-256 encryption and multiple security measures.
          </div>
        </Title>
        <CertificateList
          data-aos="fade-up"
          data-aos-duration="1000"
          data-aos-offset="60"
          data-aos-delay="200"
          scrollWidth={scrollWidth}
        >
          <div className="carousel-inner">
            {certificateList.map((item, index) => (
              <img key={index} src={item.img} alt={item.text} loading="lazy" />
            ))}
          </div>
          <div className="carousel-inner-mobile">
            {dataList.map((item, index) => (
              <img key={index} src={item.img} alt={item.text} loading="lazy" />
            ))}
          </div>
        </CertificateList>
      </CertificateWrap>
    </Certificate>
  )
}
