import React from 'react'
import ButtonList from '@raylink-overseas/common/components/buttonList'
import { BannerWrap, BannerContent, Left, Right, Title, H1 } from './atoms'
import RightImg from '../../../../images/home/banner/banner-right.png'
import Path from '@raylink-overseas/common/constants/path'

export default function Banner() {
  return (
    <BannerWrap>
      <BannerContent>
        <Left>
          <Title>
            <div className="name">Avica Remote Desktop</div>
            <H1>Secure, Simple & Seamless Remote Desktop Software</H1>
            <div className="text">
              Avica revolutionizes remote access for both individuals and
              enterprises with its exceptional performance and robust security.
              Bring everyone S.S.S. level remote desktop experience.
            </div>
          </Title>
          <ButtonList
            className="btns"
            btnInfo={[
              { text: 'Free Download', href: Path.downloads },
              { text: 'Request Business Trial', href: Path.businessLogin },
            ]}
          />
        </Left>
        <Right>
          <img src={RightImg} alt="" />
        </Right>
      </BannerContent>
    </BannerWrap>
  )
}
