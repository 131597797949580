import React from 'react'
import {
  BusinessIntroWrap,
  BusinessIntroContent,
  Title,
  H1,
  DataWrapper,
} from './atoms'
import ManagementImg from '../../../../images/home/business-intro/management.png'
import SecurityImg from '../../../../images/home/business-intro/security.png'
import DeploymentImg from '../../../../images/home/business-intro/deployment.png'
import CornerImg from '../../../../images/home/business-intro/corner.png'
import ButtonList from '@raylink-overseas/common/components/buttonList'
import Path from '@raylink-overseas/common/constants/path'

const BusinessIntro = () => {
  const dataList = [
    {
      icon: ManagementImg,
      title: 'One-Stop Management',
      desc: 'Manage and monitor accounts, roles, and devices for efficient collaboration',
      points: [
        'Group Policies',
        'User Management',
        'Device Management',
        'Role & Access Management',
      ],
    },
    {
      icon: SecurityImg,
      title: 'Comprehensive Security',
      desc: 'Incorporate robust security measures to ensure risk prevention and traceable logs',
      points: [
        'Two-factor Authentication',
        'Session History',
        'File Transfer History',
        'Secure Watermark',
      ],
    },
    {
      icon: DeploymentImg,
      title: 'Quick Deployment',
      desc: 'Adopt an easy deployment mode with high compatibility to reduce resource consumption',
      points: ['AD Integration', 'Easy Deployment', 'Cross-System Deployment'],
    },
  ]
  return (
    <BusinessIntroWrap>
      <BusinessIntroContent>
        <Title data-aos="fade-up" data-aos-duration="1000" data-aos-offset="60">
          <H1>All-In-One Remote Solution for Business</H1>
          <div
            className="text"
            data-aos="fade-up"
            data-aos-duration="1000"
            data-aos-offset="60"
          >
            Avica Business combines fast deployment, efficient management, and
            top-level security to empower teams or enterprises to enter a new
            phase of remote collaboration.
          </div>
        </Title>
        <DataWrapper
          data-aos="fade-up"
          data-aos-duration="1000"
          data-aos-offset="60"
        >
          {dataList.map((item, index) => (
            <div key={index} className="item">
              <img
                className="icon"
                src={item.icon}
                alt={item.title}
                loading="lazy"
              />
              <div className="content">
                <h3>{item.title}</h3>
                <p>{item.desc}</p>
                <ul>
                  {item.points.map((point, i) => (
                    <li key={i}>
                      <img src={CornerImg} alt="✅" loading="lazy" />
                      {point}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          ))}
        </DataWrapper>
        <ButtonList
          data-aos="fade-up"
          data-aos-duration="1000"
          data-aos-offset="60"
          className="btns"
          btnInfo={[
            { text: 'Start Business Trial', href: Path.businessLogin },
            { text: 'Learn More', href: Path.productBusiness },
          ]}
        />
      </BusinessIntroContent>
    </BusinessIntroWrap>
  )
}

export default BusinessIntro
