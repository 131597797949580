import styled from 'styled-components'
import { Media, color } from '@raylink-overseas/common/theme'
import { BigWrapper } from '@raylink-overseas/common/components'

export const Certificate = styled.div`
  width: 100%;
  background: linear-gradient(180deg, #f1f3ff 0%, #f9faff 81%, #ffffff 98%);
`
export const CertificateWrap = styled(BigWrapper)`
  padding: 112px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 67px;

  ${Media.homeLaptop`
    padding: 88px 0;
    gap: 48px;
  `}
  ${Media.laptop`
    padding: 64px 0;
    gap: 40px;
  `}
  ${Media.ipad`
    padding: 56px 0;
    gap: 24px;
  `}

  ${Media.phone`
    width: 100%;
    gap: 0.48rem;
    padding: 0.8rem 0;
  `}
`

export const Title = styled.div`
  flex-shrink: 0;
  text-align: center;
  h2 {
    color: #1f2329;
    font-size: 40px;
    font-weight: 700;
  }
  .text {
    margin-top: 8px;
    color: ${color.textDark};
    font-size: 18px;
    font-weight: 400;
  }
  ${Media.homeLaptop`
    width: 1200px;
    h2 {
      font-size: 34px;
    }
  `}
  ${Media.laptop`
    h2 {
      font-size: 32px;
    }
  `}
  ${Media.ipad`
    width: 896px;
    text-align: center;
    h2 {
      font-size: 30px;
    }
  `}

  ${Media.phone`
    width: 6.86rem;
    h2 {
      text-align: center;
      font-size: 0.48rem;
    }
    .text {
      margin-top: 0.16rem;
      text-align: center;
      font-size: 0.28rem;
    }
  `}
`

export const CertificateList = styled(BigWrapper)<{ scrollWidth: number }>`
  display: flex;
  flex-wrap: nowrap;
  justify-content: start;
  background: #fff;
  padding: 40px;
  border-radius: 12px;
  overflow-x: scroll;
  gap: 72px;
  width: 100%;
  position: relative;
  transform: translate3d(0, 0, 0);
  &::-webkit-scrollbar {
    display: none;
  }

  &::before,
  &::after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    width: 64px;
    z-index: 1;
  }
  &::before {
    left: 0;
    background: linear-gradient(270deg, rgba(255, 255, 255, 0) 0%, #ffffff 50%);
  }
  &::after {
    right: 0;
    background: linear-gradient(
      270deg,
      #ffffff 50%,
      rgba(255, 255, 255, 0) 100%
    );
  }

  .carousel-inner {
    display: flex;
    gap: 72px;
    -webkit-animation: 20s rowup linear infinite normal;
    animation: 20s rowup linear infinite normal;
    img {
      height: 64px;
    }
    // 鼠标移入停止动画
    /* &:hover {
      -webkit-animation-play-state: paused;
      animation-play-state: paused;
    } */
  }
  // scrollWidth为整个图片容器的宽度，移动的偏移量等于容器宽度加上外层容器两边的padding值除以2
  @keyframes rowup {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
    }
    100% {
      -webkit-transform: translate3d(
        -${({ scrollWidth }) => scrollWidth + 37}px,
        0,
        0
      );
    }
  }
  .carousel-inner-mobile {
    display: none;
  }

  ${Media.homeLaptop`
    width: 1200px;
    gap: 16px;
  `}
  ${Media.laptop<{ scrollWidth: number }>`
    width: 1136px;
    padding: 32px;
    @keyframes rowup {
      100% {
        -webkit-transform: translate3d(
          -${({ scrollWidth }) => scrollWidth + 24.5}px,
          0,
          0
        );
      }
    }
    .carousel-inner {
      gap: 48px;
      img {
        height: 52px;
      }
    }
  `}
  ${Media.ipad`
    width: 936px;
    padding: 24px;
  `}
  ${Media.phone`
    padding: 0.36rem 0.25rem;
    width: 6.86rem;
    border-radius: 0.16rem;
    &::before,
    &::after {
      content: none;
    }
    .carousel-inner {
      display: none;
      animation: none;
    }
    .carousel-inner-mobile {
      display: flex;
      gap: 0.4rem;
      flex-wrap: wrap;
      justify-content: center;
      img {
        height: 0.6rem;
      }
    }
  `}
`
