import React, { useEffect, useState } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import RightSvg from '@images/home/right.svg'
import ArrowLeft from '@svg/arrow-left.svg'
import ArrowRight from '@svg/arrow-right.svg'
import BlogItem from '@raylink-overseas/common/components/BlogItem'
import { useMedia } from '@raylink-overseas/common/hooks'
import {
  BlogsWrap,
  CarouselWrap,
  Carousel,
  BlogList,
  CarouselItem,
} from './atoms'
import useHref from '@raylink-overseas/common/hooks/use-href'

export default function Blogs() {
  const isMedia = useMedia()
  const paramsHref = useHref()
  const [current, setCurrent] = useState<number>(0)
  const [carouselWidth, setCarouselWidth] = useState<number>()

  const result = useStaticQuery<{
    allStrapiAvicaBlogArt: AvicaBlogArt
  }>(graphql`
    {
      allStrapiAvicaBlogArt(
        filter: { recommend: { eq: true } }
        sort: { fields: publish_time, order: DESC }
        limit: 9
      ) {
        nodes {
          slug
          title
          recommend
          categories {
            seo {
              title
              description
            }
            arts {
              brief
              slug
              title
              reading_time
            }
            slug
            name
          }
          cover {
            ext
            url
            width
            height
          }
          publish_time
        }
      }
    }
  `)
  const blogs: Blog[] = result?.allStrapiAvicaBlogArt?.nodes || []

  // web端展示9篇，移动端3篇
  const blogsData = blogs.slice(0, isMedia ? 3 : 9)
  // 每次轮播3篇
  const carouselList = blogsData.reduce((p: Blog[][]) => {
    p.push(blogsData.splice(0, 3))
    return p
  }, [])

  // 内容宽度不固定，轮播距离需要根据宽度来
  useEffect(() => {
    const carouselItem = document.querySelector('#carousel-item')
    setCarouselWidth(carouselItem?.clientWidth)
    if (typeof window === 'undefined') return undefined
    window.addEventListener('resize', () => {
      const carouselItem = document.querySelector('#carousel-item')
      setCarouselWidth(carouselItem?.clientWidth)
    })
    return () => window.removeEventListener('resize', () => setCarouselWidth(0))
  }, [])

  return (
    <BlogsWrap>
      <h2
        className="title"
        data-aos="fade-up"
        data-aos-duration="1000"
        data-aos-offset="60"
      >
        Blogs & Articles
      </h2>
      <div
        className="desc"
        data-aos="fade-up"
        data-aos-duration="1000"
        data-aos-offset="60"
        data-aos-delay="200"
      >
        Explore remote desktop solutions, remote work tips, industry events and
        more.
      </div>
      <CarouselWrap>
        <Carousel>
          <BlogList index={current} carouselWidth={carouselWidth || 1400}>
            {carouselList?.length > 0 &&
              carouselList?.map((blogs, index) => (
                <CarouselItem key={index} id="carousel-item">
                  {blogs.map((item, i) => (
                    <a
                      href={paramsHref(`/blog/${item?.slug}/`)}
                      key={`1${i}`}
                      target="_blank"
                      rel="noreferrer nofollow"
                    >
                      <BlogItem
                        className="home-blog"
                        data-aos="fade-up"
                        data-aos-duration="1000"
                        data-aos-offset="60"
                        data-aos-delay={i * 200}
                        data={item}
                        key={index}
                        hasBottom={false}
                      />
                    </a>
                  ))}
                </CarouselItem>
              ))}
          </BlogList>
        </Carousel>
        <div
          className={`${current > 0 ? '' : 'disabled'} btn btn-pre`}
          onClick={() => {
            if (current > 0) {
              setCurrent(current - 1)
            }
          }}
        >
          <ArrowLeft />
        </div>
        <div
          className={`${
            current < carouselList.length - 1 ? '' : 'disabled'
          } btn btn-next`}
          onClick={() => {
            if (current < carouselList.length - 1) {
              setCurrent(current + 1)
            }
          }}
        >
          <ArrowRight />
        </div>
        <a
          href={paramsHref('/blog/')}
          className="more"
          data-aos="fade-up"
          data-aos-duration="1000"
          data-aos-offset="60"
          target="_blank"
          rel="noreferrer nofollow"
        >
          <span>Show more</span>
          <img src={RightSvg} alt="" loading="lazy" />
        </a>
      </CarouselWrap>
    </BlogsWrap>
  )
}
