import { BigWrapper } from '@raylink-overseas/common/components'
import { Media } from '@raylink-overseas/common/theme'
import styled from 'styled-components'

export const BusinessIntroWrap = styled.div``
export const BusinessIntroContent = styled(BigWrapper)`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 112px;
  margin-bottom: 112px;
  .btns {
    margin-top: 48px;
    .first {
      width: auto;
      padding: 0 20px;
    }
  }
  ${Media.homeLaptop`
    margin-top: 88px;
    margin-bottom: 88px;
  `}
  ${Media.laptop`
    margin-top: 64px;
    margin-bottom: 64px;
  `}
  ${Media.ipad`
    margin-top: 56px;
    margin-bottom: 56px;
    .btns {
      margin-top: 32px;
    }
  `}
  ${Media.phone`
    margin-top: 0;
    margin-bottom: 0.8rem;
    .btns {
      margin-top: 0.16rem;
    }
  `}
`
export const Title = styled.div`
  text-align: center;
  width: 920px;
  .text {
    color: #595959;
    font-size: 18px;
  }
  ${Media.homeLaptop`
    width: 788px;
  `}
  ${Media.ipad`
    font-size: 16px;
  `}
  ${Media.phone`
    width: 6.26rem;
    .text {
      font-size: 0.28rem;
    }
  `}
`
export const H1 = styled.h2`
  font-size: 40px;
  font-weight: 700;
  ${Media.homeLaptop`
    font-size: 34px;
  `}
  ${Media.laptop`
    font-size: 32px;
  `}
  ${Media.ipad`
    font-size: 30px;
  `}
  ${Media.phone`
    font-size: 0.48rem;
  `}
`

export const DataWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 64px;
  width: 100%;
  gap: 40px;
  .item {
    border-radius: 20px;
    padding: 48px 56px;
    background: linear-gradient(180deg, #f2f7ff 0%, #eff6ff 74%, #ddebff 100%),
      radial-gradient(
        83.91% 54.05% at 47.28% 43.19%,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 0.5) 100%
      );
    .icon {
      width: 58px;
      height: 58px;
    }
    .content {
      width: 328px;
      h3 {
        color: #1f2329;
        font-size: 32px;
        font-weight: 700;
        margin: 0;
        width: 262px;
      }
      p {
        color: #595959;
        font-size: 18px;
        font-weight: 400;
        margin-top: 8px;
      }
      ul {
        margin-top: 32px;
        li {
          display: flex;
          gap: 12px;
          font-size: 16px;
          color: #595959;
          margin-bottom: 12px;
          align-items: center;
          img {
            height: 16px;
            width: 16px;
          }
        }
      }
    }
  }
  ${Media.homeLaptop`
    margin-top: 48px;
    gap: 32px;
    .item {
      padding: 40px 48px;
      .content {
        width: 282px;
        ul {
          margin-top: 24px;
        }
      }
    }
  `}
  ${Media.laptop`
    margin-top: 40px;
    gap: 24px;
    .item {
      padding: 32px 40px;
      .icon {
        width: 54px;
        height: 54px;
      }
      .content {
        width: 277px;
        h3 {
          font-size: 28px;
          width: 230px;
        }
        ul {
          margin-top: 20px;
        }
      }
    }
  `}
  ${Media.ipad`
    margin-top: 32px;
    .item {
      border-radius: 16px;
      padding: 32px;
      .icon {
        width: 48px;
        height: 48px;
      }
      .content {
        width: 235px;
        h3 {
          font-size: 26px;
        }
        ul {
          margin-top: 16px;
          li {
            margin-bottom: 8px;
            white-space: nowrap;
            gap: 6px;
            font-size: 14px;
            img {
              width: 14px;
              height: 14px;
            }
          }
        }
      }
    }
  `}
  ${Media.phone`
    flex-direction: column;
    align-items: center;
    padding: 0.48rem;
    gap: 0.28rem;
    margin-top: 0;
    .item {
      border-radius: 0.24rem;
      width: 6.86rem;
      .icon {
        width: 0.88rem;
        height: 0.88rem;
      }
      .content {
        width: 5.9rem;
        h3 {
          font-size: 0.4rem;
        }
        ul {
          margin-top: 0.32rem;
        }
      }
    }
  `}
`
